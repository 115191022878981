import React from "react";
import HeaderComponent from "./HeaderComponent";
import MainComponent from "./MainComponent";
import Slider from "./Slider";
import FeatureComponent from "./FeatureComponent";
import OuruserComponent from "./OuruserComponent";
import ChooseComponent from "./ChooseComponent";
import { useEffect, useState } from "react";
const LandingComponent = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    setWidth(window.innerWidth);
  });
  return (
    <div className="landing-page">
      <HeaderComponent />
      <MainComponent />
      {width > 1200 ? <Slider /> : <></>}
      <FeatureComponent />
      <OuruserComponent />
      <ChooseComponent />
    </div>
  );
};

export default LandingComponent;
