import React from "react";
import { downloadResumeFull } from "../../Assets";
const DownloadResumeCard = () => {
  return (
    <div className="steps-card-div">
      <img src={downloadResumeFull} alt="ResumeView" />
    </div>
  );
};

export default DownloadResumeCard;
