import React from "react";
import { pickTemplate } from "../../Assets";
import { pickTemplateAfter } from "../../Assets";
import UserInfo from "./UserInfo";
import { arrowRotated } from "../../Assets/index.js";
const PickTemplateCard = () => {
  return (
    <div className="steps-card-div">
      <div className="step-form-unfilled">
        <img src={pickTemplate} alt="PickTemplate" className="pick-template-image"/>
        <UserInfo />
      </div>
      <div className="step-form-filled">
        <div className="filled-form-header">
          <p>Transforming Concepts into Visually Stunning Realities</p>
          <img
            src={arrowRotated}
            alt="RotatedArrow"
            className="arrow-rotated"
          />
        </div>
        <img src={pickTemplateAfter} alt="PickTemplate" className="pick-template-image" />
      </div>
    </div>
  );
};

export default PickTemplateCard;
