import React, { useCallback, useEffect, useState, useRef } from "react";
import HowItWorkMobile from "./HowItWorkMobile";
import HowItWorkWeb from "./HowItWorkWeb";
import "./HowItWork/styles.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const MainComponent = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    setWidth(window.innerWidth);
  });
  return (
    <div className="component-div">
      <div className="separator-tag">
        <p className="">HOW IT WORKS</p>
      </div>
      <div className="main-header-div">
        <div className="main-headline">
          Building Bridges Between
          <span>
            {" "}
            Imagination <span> and Reality</span>
          </span>
        </div>
        <div className="main-lines">
          <div className="main-line-1"></div>
          <div className="main-line-2"></div>
        </div>
        <p>
          "Welcome to the ultimate portfolio creation platform! Express your
          unique talent and achievements with a personalized digital showcase.
          But that's not all – we go beyond portfolios. Connect and network with
          like-minded professionals, fostering collaboration and growth. Plus,
          our innovative job-matching feature ensures that job opportunities
          align with your skills and goals. Your journey to a thriving career
          starts here, all on a single, user-friendly platform."
        </p>
      </div>
      {width <= 1200 ? <HowItWorkMobile /> : <HowItWorkWeb />}
    </div>
  );
};

export default MainComponent;
