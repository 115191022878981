import React from "react";
import './Feature/styles.css'
import {
  twoPeopleChat,
  featureJob,
  featureMentor,
  featureProfile,
} from "../../Assets";
const FeatureComponent = () => {
  return (
    <div className="component-div">
      <div className="separator-tag">
        <p className="">WHAT WE PROVIDE</p>
      </div>
      <div className="main-header-div">
        <div className="feature-headline">
          <span id="feature-headline-span-gradient">Our Key {"  "}</span>
          <span id="feature-headline-span-white-text"> Feature</span>
        </div>
        <div className="main-lines">
          <div className="main-line-1"></div>
          <div className="main-line-2"></div>
        </div>
        <div className="feature-description">
          {/* In publishing and graphic design. */}
        </div>
      </div>
      <div className="feature-content">
        <div className="feature-options">
          <div className="feature-profile">
            <img src={featureProfile} alt="Feature Profile Image" />
            <div className="feature-profile-content">
              <h6>Customizable profile</h6>
              <p>
                There are websites and platforms that connect mentees with
                mentors in various fields. Examples include MentorCruise.
              </p>
            </div>
          </div>
          <div className="feature-mentor">
            <img src={featureMentor} alt="Feature Mentor Image" />
            <div className="feature-mentor-content">
              <h6>Connects with mentor</h6>
              <p>
                There are websites and platforms that connect mentees with
                mentors in various fields. Examples include MentorCruise
              </p>
            </div>
          </div>
          <div className="feature-job">
            <img src={featureJob} alt="Feature Job Image" />
            <div className="feature-job-content">
              <h6>
                Auto job applier(graph of your resume ATS particular role)
              </h6>
              <p>
                There are websites and platforms that connect mentees with
                mentors in various fields. Examples include MentorCruise
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="feature-image-left-skew">
            <div className="feature-image">
              <img
                src={twoPeopleChat}
                alt="Feature Image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureComponent;
