import React, { useEffect } from "react";
import UserForm from "./UserForm";
import UserInfo from "./UserInfo";
import { useState } from "react";
import { arrowRotated, userFilledForm} from "../../Assets/index.js";
import { userUnfilledForm } from "../../Assets/index.js";
const FillBlanksCard = () => {
  return (
    <div className="steps-card-div">
      <div className="step-form-unfilled">
        <img src={userUnfilledForm} alt="userunfilledform" />
        <UserInfo />
      </div>
      <div className="step-form-filled">
        <div className="filled-form-header">
          <p>Transforming Concepts into Visually Stunning Realities</p>
          <img
            src={arrowRotated}
            alt="RotatedArrow"
            className="arrow-rotated"
          />
        </div>
        <img src={userFilledForm} alt="userFilledForm" />
      </div>
    </div>
  );
};

export default FillBlanksCard;
