import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
import Slider from "./Slider";
import { downloadResume } from "../../Assets";
import { pickTemplate } from "../../Assets";
import UserForm from "./UserForm";
import "react-slideshow-image/dist/styles.css";
import "./HowItWork/styles.css";
const HowItWorkWeb = () => {
  const filledData = {
    fname: "abc",
    lname: "abc",
    education: "UI/UX Designer",
    email: "abc@gmail.com",
    phone: "XXXXXXXXX",
    city: "Mumbai",
    state: "Maharashtra",
    pincode: "XXXXX",
  };
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      let newInd = (index + 1) % 3;
      setIndex(newInd);
    }, 6000);
  }, [index]);
  return (
    <div className="main-steps-div">
      <div className="main-step">
        <div className="step-icon">
          <div
            className={
              index == 0
                ? "step-icon-type step-icon-type-active"
                : "step-icon-type"
            }
          >
            1
          </div>
        </div>
        <div className="step-content">
          <h6>Pick a template</h6>
          <p>Select a resume template that embodies your style</p>
        </div>
      </div>
      <div className="step-gap-line"></div>
      <div className="main-step">
        <div className="step-icon">
          <div
            className={
              index == 1
                ? "step-icon-type step-icon-type-active"
                : "step-icon-type"
            }
          >
            2
          </div>
        </div>
        <div className="step-content">
          <h6>Fill in the blanks</h6>
          <p>Start by filling in your resume details</p>
        </div>
      </div>
      <div className="step-gap-line"></div>
      <div className="main-step">
        <div className="step-icon">
          <div
            className={
              index == 2
                ? "step-icon-type step-icon-type-active"
                : "step-icon-type"
            }
          >
            3
          </div>
        </div>
        <div className="step-content">
          <h6>Download your resume</h6>
          <p>Download your resume instantly and make changes afterwards</p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorkWeb;
