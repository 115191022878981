import React from "react";
import LandingComponent from "../Components/LandingFolder/LandingComponent";
import Footer from "../Components/LandingFolder/Footer";
import Navbar from "../Components/LandingFolder/NavBar";
const LandingPage = () => {
  return (
    <div className="landing-page-div">
      <Navbar />
      <LandingComponent />
      <div className="page-footer">
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
