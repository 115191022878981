import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import FillBlanksCard from "./FillBlanksCard";
import PickTemplateCard from "./PickTemplateCard";
import DownloadResumeCard from "./DownloadResumeCard";
const Slider = () => {
  return (
    <Slide
      autoplay={true}
      duration={4000}
      transitionDuration={2000}
      className="slider-div"
      pauseOnHover={false}
    >
      <div className="each-slide-effect">
        <FillBlanksCard />
      </div>
      <div className="each-slide-effect">
        <PickTemplateCard />
      </div>
      <div className="each-slide-effect">
        <DownloadResumeCard />
      </div>
    </Slide>
  );
};

export default Slider;
