import React from "react";
import { circleConnector, downloadIcon } from "./../../Assets/index";
import { fastIcon } from "./../../Assets/index";
import { professionalIcon } from "./../../Assets/index";
import { robustText } from "./../../Assets/index";
import "./WhyChooseUs/styles.css";

const ChooseComponentMobile = () => {
  return (
    <div className="choose-component-mobile">
      <div className="choose-component-mobile-item">
        <div className="choose-mobile-header">
          <h6>40+ Professional Resume Templates</h6>
          <p>Choose from over thirty modern and professional templates. </p>
        </div>
        <div className="choose-mobile-image choose-mobile-image-left">
          <img
            src={professionalIcon}
            alt="verfiedSymbol"
            className="choose-img"
          />
        </div>
      </div>
      <div className="choose-component-mobile-item">
        <div className="choose-mobile-image choose-mobile-image-right">
          <img src={fastIcon} alt="verfiedSymbol" className="choose-img" />
        </div>
        <div className="choose-mobile-header">
          <h6>Fast, Free, and Easy to Use</h6>
          <p>
            Our resume builder lets you easily and quickly create a resume using
            our resume wizard.
          </p>
        </div>
      </div>
      <div className="choose-component-mobile-item">
        <div className="choose-mobile-header">
          <h6>Robust Text Editor</h6>
          <p>
            Our text editor has everything you need to customize your resume
          </p>
        </div>
        <div className="choose-mobile-image  choose-mobile-image-left">
          <img src={robustText} alt="verfiedSymbol" className="choose-img" />
        </div>
      </div>
      <div className="choose-component-mobile-item">
        <div className="choose-mobile-image choose-mobile-image-right">
          <img src={downloadIcon} alt="verfiedSymbol" className="bigo" />
        </div>
        <div className="choose-mobile-header">
          <h6>Download your resume as PDF</h6>
          <p>Download your resume and send it straight to the hiring manager</p>
        </div>
      </div>
    </div>
  );
};

export default ChooseComponentMobile;
