import React, { useEffect, useState } from "react";
import "./style.css";
import { Cross, Hamburger, companyLogo, searchIcon } from "../../Assets";
import NavLinks from "./NavLinks";
import SearchBox from "./SearchBox";
import "./Navbar/style.css";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [showHamburger, setShowHamburger] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1007) {
      setShowHamburger(false);
    } else {
      setShowHamburger(true);
    }
  });

  window.addEventListener("resize", function () {
    if (window.innerWidth >= 1007) {
      setShowSearchBar(false);
      setShowHamburger(false);
    } else {
      setShowHamburger(true);
    }
    setClicked(false);
  });

  const handleButtonClick = () => {
    setShowSearchBar(false);
    if (clicked) {
      setClicked(false);
    } else {
      setClicked(true);
    }
  };

  return (
    <div className={!clicked ? "navbar-div" : "navbar-clicked-div"}>
      <div className="left-nav-div">
        <img
          src={companyLogo}
          alt="company-logo"
          className="company-logo"
        ></img>
        {!showHamburger ? (
          <div className="hamburger-div">
            <NavLinks />
            <div className="right-nav-div">
              {/* <SearchBox /> */}
              <button className="signIn-btn">Sign in</button>
              <button className="signUp-btn active-btn-nav">Sign up</button>
            </div>
          </div>
        ) : (
          <div className="search-hamburger-div">
            {/* <img
              src={!showSearchBar ? searchIcon : Cross}
              alt="search-icon"
              onClick={() => {
                setClicked(false);
                setShowSearchBar(!showSearchBar);
              }}
              className={!showSearchBar ? "navbar-search-icon" : "cross"}
            /> */}
            <img
              src={clicked ? Cross : Hamburger}
              alt={clicked ? "cross" : "hamburger"}
              className={clicked ? "cross" : "hamburger"}
              onClick={handleButtonClick}
            />
          </div>
        )}
      </div>
      {clicked && (
        <div className="hamburger-div">
          <NavLinks />
          <div className="hamburger-line" />
          <div className="right-nav-div">
            <div
              className={
                !clicked ? "signUp-btn active-btn-nav" : "signUp-hamburger"
              }
            >
              <p className={!clicked ? "" : "signUp-hamburger-para"}>Sign up</p>
            </div>

            <div className={!clicked ? "signIn-btn" : "signIn-hamburger"}>
              <p className={!clicked ? "" : "signIn-hamburger-para"}>Sign in</p>
            </div>
          </div>
        </div>
      )}
      {/* {showSearchBar && <SearchBox />} */}
    </div>
  );
};

export default Navbar;
