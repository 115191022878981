import React, { useEffect, useState } from "react";
import { circleConnector, downloadIcon } from "./../../Assets/index";
import { fastIcon } from "./../../Assets/index";
import { professionalIcon } from "./../../Assets/index";
import { robustText } from "./../../Assets/index";
import './WhyChooseUs/styles.css'
import ChooseComponentMobile from "./ChooseComponentMobile";
const ChooseComponent = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    setWidth(window.innerWidth);
  });
  return (
    <div className="component-div choose-component">
      <div className="separator-tag">
        <p className="">WHY CHOOSE US</p>
      </div>
      <div className="main-header-div">
        <div className="feature-headline">
          <div>
            <span id="feature-headline-span-gradient">
              A Quick and Easy {"  "}
            </span>
            <span id="feature-headline-span-white-text">
              Way to Create Your Professional Resume
            </span>
          </div>
        </div>
        <div className="main-lines">
          <div className="main-line-1"></div>
          <div className="main-line-2"></div>
        </div>
        <div className="feature-description">
          {/* In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form */}
        </div>
      </div>
      {width > 1200 ? (
        <div>
          <div className="choose-content-image">
            <img
              src={professionalIcon}
              alt="verfiedSymbol"
              className="verified-circle"
            />
            <img
              src={circleConnector}
              alt="SymbolConnector"
              className="circle-connector"
            />
            <img
              src={fastIcon}
              alt="verfiedSymbol"
              className="verified-circle"
            />
            <img
              src={circleConnector}
              alt="SymbolConnector"
              className="circle-connector"
            />
            <img
              src={robustText}
              alt="verfiedSymbol"
              className="verified-circle"
            />
            <img
              src={circleConnector}
              alt="SymbolConnector"
              className="circle-connector"
            />
            <img
              src={downloadIcon}
              alt="verfiedSymbol"
              className="verified-circle"
            />
          </div>
          <div className="choose-content-header">
            <div className="choose-content-item">
              <h6>40+ Professional Resume Templates</h6>
            </div>
            <div className="choose-content-item">
              <h6>Fast, Free, and Easy to Use</h6>
            </div>
            <div className="choose-content-item">
              <h6>Robust Text Editor</h6>
            </div>
            <div className="choose-content-item">
              <h6>Download your resume as PDF</h6>
            </div>
          </div>
          <div className="choose-content-paragraph">
            <p>Choose from over thirty modern and professional templates. </p>
            <p>
              Our resume builder lets you easily and quickly create a resume
              using our resume wizard.
            </p>
            <p>
              Our text editor has everything you need to customize your resume
            </p>
            <p>
              Download your resume and send it straight to the hiring manager
            </p>
          </div>
        </div>
      ) : (
        <ChooseComponentMobile />
      )}
    </div>
  );
};

export default ChooseComponent;
