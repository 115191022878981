import React from "react";
import { headlineDeco } from "../../Assets/index";
import { arrowRight } from "../../Assets/index";
import { personImage } from "../../Assets/index";
import { circleProject } from "../../Assets/index";
import "./style.css";
import "./HeroSection/styles.css";

const HeaderComponent = () => {
  return (
    <div className="header-div">
      <div className="header-left-div">
        <div className="header-headline">
          Generate and {"  "}
          <span className="header-headline-span-gradient-low">
            publish your portfolio within 2{" "}
          </span>
          <span className="header-headline-span-gradient-high"> minutes.</span>
        </div>
        <img src={headlineDeco} alt="UnderLine" className="deco" />
        <div>
          <div className="header-explain">
            <div className="header-explain-in">
              <p className="header-description">
                Elevate Your Portfolio, Network, and Career Prospects! Craft
                Your Showcase, Connect with Opportunities, and Let Jobs Find You
                - All in One Place.
              </p>
              <button className="cv-btn">
                <span>
                  <p>Create Your CV Now</p>
                  <img src={arrowRight} alt="ArrowRight" />
                </span>
              </button>
            </div>
            <img src={circleProject} alt="ProjectCircle" className="circle" />
          </div>
        </div>
      </div>
      <img src={personImage} alt="StandingPerson" className="person" />
    </div>
  );
};

export default HeaderComponent;
