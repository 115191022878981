import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
import Slider from "./Slider";
import { downloadResume } from "../../Assets";
import { pickTemplate } from "../../Assets";
import { userFilledForm } from "../../Assets";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "react-slideshow-image/dist/styles.css";
const HowItWorkMobile = () => {
  const [index, setIndex] = useState(1);
  return (
    <div className="main-steps-div">
      <Slide autoplay={true} duration={30000} transitionDuration={2000}>
        <div className="each-slide-effect">
          <div className="step-div-mobile">
            <div className="main-step">
              <div className="step-icon">
                <div className="step-icon-type step-icon-type-active">1</div>
              </div>
              <div className="step-content">
                <h6>Pick a template</h6>
                <p>Select a resume template that embodies your style</p>
              </div>
            </div>
            <img src={pickTemplate} alt="PickTemplateIcon" />
          </div>
        </div>
        <div className="each-slide-effect">
          <div className="step-div-mobile">
            <div className="main-step">
              <div className="step-icon">
                <div className="step-icon-type step-icon-type-active">2</div>
              </div>
              <div className="step-content">
                <h6>Fill in the blanks</h6>
                <p>Start by filling in your resume details</p>
              </div>
            </div>
            <img src={userFilledForm} alt="UserFilledForm" />
          </div>
        </div>
        <div className="each-slide-effect">
          <div className="step-div-mobile">
            <div className="main-step">
              <div className="step-icon">
                <div className={"step-icon-type step-icon-type-active"}>3</div>
              </div>
              <div className="step-content">
                <h6>Download your resume</h6>
                <p>
                  Download your resume instantly and make changes afterwards
                </p>
              </div>
            </div>
            <img src={downloadResume} alt="resumeTemplate" />
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default HowItWorkMobile;
