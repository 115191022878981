import React from "react";
import { addLink } from "../../Assets/index.js";
import { arrowLeft } from "../../Assets/index.js";
import { arrowRightColored } from "../../Assets/index.js";
import { userImage } from "../../Assets/index.js";
const UserForm = ({ data }) => {
  return (
    <div className="user-form-box">
      <div className="user-form-div">
        <div className="form-header">
          <h6>What’s the best way for employers to contact you?</h6>
          <p>We suggest including an email and phone number.</p>
        </div>
        <div className="form-content">
          <div className="user-info">
            <div className="user-image-box">
              <img src={userImage} alt="UserImage" className="user-image"></img>
            </div>
            <button className="upload-btn">
              {" "}
              <span>Upload Photo</span>
            </button>
          </div>
          <div className="user-data">
            <div className="data-item">
              <div className="input-item">
                <span className="input-text">{data.fname}</span>
              </div>
              <div className="input-item">
                <span className="input-text">{data.lname}</span>
              </div>
            </div>
            <div className="data-item">
              <div className="input-item">
                <span className="input-text">{data.education}</span>
              </div>
            </div>
            <div className="data-item">
              <div className="input-item">
                <span className="input-text">{data.phone}</span>
              </div>
              <div className="input-item">
                <span className="input-text">{data.email}</span>
              </div>
            </div>
            <div className="data-item">
              <div className="input-item">
                <span className="input-text">{data.city}</span>
              </div>
              <div className="input-item">
                <span className="input-text">{data.state}</span>
              </div>
              <div className="input-item">
                <span className="input-text">{data.pincode}</span>
              </div>
            </div>
            <div className="add-socials">
              <img src={addLink} alt="AddSymbol" />
              <p>Add Social Links</p>
            </div>
            <div className="form-separator"></div>
            <div className="form-btns">
              <button className="form-back-btn">
                <img src={arrowLeft} alt="ArrowLeft"></img>
                <p>Back</p>
              </button>
              <button className="form-next-btn">
                <p>Next</p>
                <img src={arrowRightColored} alt="ArrowRight"></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
