import React, { useEffect, useState } from "react";
import { reachBackground } from "../../Assets";
import { runningMan } from "../../Assets";
import "./style.css";
import "./OurUser/styles.css";
const OuruserComponent = () => {
  const [countActiveUser, setCountActiveUser] = useState(0);
  const [currentActiveUser, setCurrentActiveUser] = useState(2500);
  useEffect(() => {
    const incrementCounter = () => {
      if (countActiveUser < currentActiveUser) {
        setCountActiveUser(countActiveUser + 1);
      }
    };
    const interval = setInterval(incrementCounter, 0.01); //Speed Adjust
    return () => {
      clearInterval(interval);
    };
  }, [countActiveUser, currentActiveUser]);

  return (
    <div className="component-div">
      <div className="separator-tag">
        <p className="">OUR USER</p>
      </div>
      <div className="main-header-div">
        <div className="feature-headline">
          <span id="feature-headline-span-gradient">Active {"  "}</span>
          <span id="feature-headline-span-white-text"> User</span>
        </div>
        <div className="main-lines">
          <div className="main-line-1"></div>
          <div className="main-line-2"></div>
        </div>
        <div className="feature-description">
          "Our success story is written by our active users who keep our
          platform buzzing with life. Join the ranks of engaged members and
          experience a world of opportunities waiting for you."
        </div>
      </div>
      <div className="ourUser-content">
        <div className="reach-count">
          <p>{countActiveUser}+</p>
        </div>
      </div>
    </div>
  );
};

export default OuruserComponent;
