import React from "react";
import "./style.css";
import "./Footer/styles.css";
import { companyLogo } from "./../../Assets/index";
import { facebook } from "./../../Assets/index";
import { twitter } from "./../../Assets/index";
import { instagram } from "./../../Assets/index";
import { ball } from "./../../Assets/index";

const Footer = () => {
  return (
    <div className="footer-div">
      <div className="footer-content">
        <div className="footer-header">
          <img src={companyLogo} alt="ZantacLogo" className="logo-image" />
          <p>
            Explore endless possibilities with us. Connect with our vibrant
            community. Achieve your goals and dreams. Join us today and start
            your journey.
          </p>
          <div className="footer-icons-list">
            <button className="footer-icons">
              <img src={facebook} alt="FacebookLogo" className="footer-icon" />
            </button>
            <button className="footer-icons">
              <img src={twitter} alt="TwitterLogo" className="footer-icon" />
            </button>
            <button className="footer-icons">
              <img
                src={instagram}
                alt="InstagramLogo"
                className="footer-icon"
              />
            </button>
            <button className="footer-icons">
              <img src={ball} alt="FootLogo" className="footer-icon" />
            </button>
          </div>
        </div>

        <div className="footer-links">
          <div className="footer-company">
            <h6>
              Company
              <div className="footer-lines">
                <div className="footer-complete-line"></div>
                <div className="footer-half-line"></div>
              </div>
            </h6>
            <div>
              <p>Explore</p>
              <p>About</p>
              <p>Contact Us</p>
              <p>Our Blog</p>
              <p>FAQ</p>
            </div>
          </div>
          <div className="footer-account">
            <h6>
              My Account
              <div className="footer-lines">
                <div className="footer-complete-line"></div>
                <div className="footer-half-line"></div>
              </div>
            </h6>
            <div>
              <p>Authors</p>
              <p>Collections</p>
              <p>Author Profile</p>
              <p>Create Item</p>
            </div>
          </div>
        </div>

        <div className="footer-stay-updated">
          <h6>
            Stay Informed By Newsletter
            <div className="footer-lines">
              <div className="footer-complete-line"></div>
              <div className="footer-half-line"></div>
            </div>
          </h6>
          <p>
            *Subscribe to our newsletter to receive early discount offers and
            updates.
          </p>
          <div className="footer-btn">
            <input
              type="text"
              placeholder="Email"
              className="send-mail-input"
            />
            <button className="send-mail-btn">
              <p>Send</p>
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="footer-complete-line"></div>
        <div className="footer-end">
          Copyright © {new Date().getFullYear()} <a href="/">Profolionet</a>.
        </div>
      </div>
    </div>
  );
};

export default Footer;
