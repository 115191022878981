import React from "react";
import { arrowDown } from "../../Assets";
const NavLinks = () => {
  return (
    <div className="nav-link-div">
      <div className="nav-item">
        <a href="" className="nav-text">
          Products
        </a>
        <img src={arrowDown} alt="ArrowDown" className="nav-icon" />
        {/* <div class="dropdown-content">
          <a href="#">Get Customized Profile</a>
          <a href="#">Connect with Mentors</a>
          <a href="#">Get Auto job Applier</a>
        </div> */}
      </div>
      <div className="nav-item">
        <a href="" className="nav-text">
          Company
        </a>
        <img src={arrowDown} alt="ArrowDown" className="nav-icon" />
        {/* <div class="dropdown-content">
          <a href="#">Who We Are</a>
          <a href="#">Customer Support</a>
          <a href="#">Career</a>
          <a href="#">Leadership</a>
          <a href="#">Investors</a>
        </div> */}
      </div>
      <div className="nav-item">
        <a href="" className="nav-text">
          Price
        </a>
      </div>
    </div>
  );
};

export default NavLinks;
