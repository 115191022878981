import React from "react";
import { manImage } from "./../../Assets/index";
import { message } from "./../../Assets/index";
import { addRequest } from "./../../Assets/index";
import { meet } from "./../../Assets/index";
import { share } from "./../../Assets/index";
import { eyeView } from "./../../Assets/index";
import { arrowDownRotated } from "./../../Assets/index";
const UserInfo = () => {
  return (
    <div className="user-info-main">
      <div className="user-link">
        <div className="separator-tag separator-link">
          <p>https://pofolionet.com/cody_profile</p>
        </div>
        <img
          src={arrowDownRotated}
          alt="ArrowDownRotated"
          className="arrow-down-rotated"
        />
      </div>
      <div className="user-info-div">
        <div className="user-handles">
          <img src={manImage} alt="profilePic" className="profile-image" />
          <div className="handles-list">
            <div className="handle-item">
              <img src={addRequest} alt="RequestUser" className="handle-icon" />
            </div>
            <div className="handle-item">
              <img src={meet} alt="Meet" className="handle-icon" />
            </div>
            <div className="handle-item">
              <img src={message} alt="Message" className="handle-icon" />
            </div>
            <div className="handle-item">
              <img src={share} alt="Share" className="handle-icon" />
            </div>
          </div>
          <p>© 2023 Portfolio. All Reserved</p>
        </div>
        <div className="user-profile">
          <div className="profile-data">
            <header className="user-name">Cody Fisher</header>
            <header className="user-work">
              UI/UX Designer / Game designer
            </header>
            <header className="user-location">Based in Los Angeles, CA</header>
          </div>
          <div>
            <div className="resume-view">
              <img src={eyeView} alt="EyeImage" />
              <p>View Resume</p>
            </div>
            <div className="view-shadow"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
